import React from 'react';
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import MainBanner from '../components/App/MainBanner';
import Solutions from '../components/App/Solutions';
import ProjectStartArea from '../components/App/ProjectStartArea';


const Home = () => {
    return (
        <Layout>
            <NavbarTwo />
            <MainBanner />
            <Solutions />
            <ProjectStartArea /> 
            <Footer />
        </Layout>
    );
}

export default Home;