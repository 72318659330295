import React from 'react'
import { Link } from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'
import shape2 from '../../assets/images/shape/circle-shape2.png'
import residentialIcon from '../../assets/images/residential-icon.png'
import enterprizeIcon from '../../assets/images/enterprize-icon.png'
import solutionIcon from '../../assets/images/solution-icon.png'


const Solutions = () => {
    return (
        <section className="services-area ptb-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="StarIcon" />
                        OUR SERVICES
                    </span>
                    <h2>We Offer Professional Solutions For Business & Residential</h2>
                    <p>
                        Cobranet currently offers corporate internet plans for organizations and medium sized businesses over
                        a state- of- the -art -radio network in Lagos, Abuja and Major Cities as well as residential plans
                        on a network topology which is rugged, dynamic and fully redundant with negligible downtimes.
                        We provide reliable services to the Nigerian market and position ourselves as a leader in this sector.
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={residentialIcon} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-residential-details">
                                    Residential Services
                                </Link>
                            </h3>
                            <p>
                                Data Services based on Mobile Wireless to ensure secure and reliable connections on our network.

                            </p>
                            <Link to="/service-residential-details" className="read-more-btn">
                                View Details <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={enterprizeIcon} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-dedicated-details">
                                    Enterprise Services
                                </Link>
                            </h3>
                            <p>
                                Highly customized according to client’s requirements.
                                Data Services based on Fixed Wireless and perfect for Inter-branching and Interconnection.
                            </p>
                            <Link to="/service-dedicated-details" className="read-more-btn">
                                View Details <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={solutionIcon} alt="banner" />
                            </div>
                            <h3>
                                <Link to="/service-managed-details">
                                    Solutions & Managed Services
                                </Link>
                            </h3>
                            <p>
                                Our Managed and Professional Services position COBRANET as a provider of a complete solution
                                in the ICT market.
                            </p>
                            <Link to="/service-managed-details" className="read-more-btn">
                                View Details <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="circle-shape2">
                <img src={shape2} alt="banner" />
            </div>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </section>
    )
}

export default Solutions;